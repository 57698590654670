<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_graduation_requirement_report')" :isColumns="true"
                        @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('students')" @filter-div-status="datatable.filterStatus = $event">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus" :filterButtonClass="'mt-1'" @filter="filter"
                              @filterClear="filterClearAndGetRow"
                              :exportExcel="checkPermission('studentprogram_graduationrequirementreportexcelexport')"
                              @exportExcel="exportExcel">
                <ValidationObserver ref="form">
                    <b-row>
                        <b-col sm="4">
                            <b-form-group :label="$t('registration_year')">
                                <academic-years-selectbox
                                    v-model="datatable.queryParams.filter.registration_academic_year"></academic-years-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col sm="4">
                            <b-form-group :label="$t('registration_semester')">
                                <semesters-selectbox
                                    v-model="datatable.queryParams.filter.registration_semester"></semesters-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group :label="$t('student_status')" class="mb-0">
                                <parameter-selectbox v-model="datatable.queryParams.filter.student_status"
                                                     :code="'student_statuses'"/>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <ValidationProvider name="faculty_code" rules="required" v-slot="{ errors }">
                                <b-form-group :label="$t('faculty')">
                                    <faculty-selectbox :validate-error="errors[0]"
                                                       v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col md="4">
                            <b-form-group :label="$t('department')">
                                <department-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                                      v-model="datatable.queryParams.filter.department_code"></department-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group :label="$t('program_code')">
                                <program-selectbox v-model="datatable.queryParams.filter.program_code"
                                                   :faculty_code="datatable.queryParams.filter.faculty_code"
                                                   :department_code="datatable.queryParams.filter.department_code"></program-selectbox>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group :label="$t('name')" class="mb-0">
                                <b-form-input id="input-formatter"
                                              v-model="datatable.queryParams.filter.name"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group :label="$t('surname')" class="mb-0">
                                <b-form-input id="input-formatter"
                                              v-model="datatable.queryParams.filter.surname"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group :label="$t('student_number')" class="mb-0">
                                <b-form-input id="input-formatter"
                                              v-model="datatable.queryParams.filter.student_number"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="2">
                            <b-form-group :label="$t('national_id')" class="mb-0">
                                <b-form-input id="input-formatter"
                                              v-model="datatable.queryParams.filter.national_id"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group :label="$t('class')">
                                <classes-selectbox v-model="datatable.queryParams.filter.class"/>
                            </b-form-group>
                        </b-col>

                        <b-col cols="4">
                            <b-form-group :label="$t('total_ects_range_completed')">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">

                                            <b-form-group>
                                                <b-form-input v-mask="'###'"

                                                              v-model="datatable.queryParams.filter.completed_ects_credits_greater_than"></b-form-input>
                                            </b-form-group>

                                    </div>
                                    <div class="px-3">
                                        <label>-</label>
                                    </div>
                                    <div class="flex-grow-1">
                                        <ValidationProvider
                                            v-slot="{ errors }">
                                            <b-form-group>
                                                <b-form-input v-mask="'###'"
                                                              v-model="datatable.queryParams.filter.completed_ects_credits_smaller_than"></b-form-input>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group :label="$t('total_credit_range_completed')">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <b-form-group>
                                            <b-form-input v-mask="'###'"

                                                          v-model="datatable.queryParams.filter.completed_credits_greater_than"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="px-3">
                                        <label>-</label>
                                    </div>
                                    <div class="flex-grow-1">
                                        <b-form-group>
                                            <b-form-input v-mask="'###'"
                                                          v-model="datatable.queryParams.filter.completed_credits_smaller_than"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group :label="$t('completed_required_course_count')" class="mb-0">
                                <b-form-input id="input-formatter"
                                              v-model="datatable.queryParams.filter.completed_required_course_count"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group :label="$t('gno_between')">
                                <div class="d-flex align-items-center">
                                    <div class="flex-grow-1">
                                        <b-form-group>
                                            <b-form-input type="number" v-model="datatable.queryParams.filter.cgpa_between_start"></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="px-3">
                                        <label>-</label>
                                    </div>
                                    <div class="flex-grow-1">
                                        <b-form-group>
                                            <b-form-input type="number" v-model="datatable.queryParams.filter.cgpa_between_end"></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>


                    </b-row>
                </ValidationObserver>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                       @on-per-page-change="onPerPageChange" v-show="datatable.showTable"/>

        </app-layout>
    </div>
</template>
<script>
//Layout
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";
//Component
import Datatable from "@/components/datatable/Datatable";
import DatatableFilter from "@/components/datatable/DatatableFilter";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import StudentProgramService from "@/services/StudentProgramService";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
//Plugin
import qs from "qs";

export default {
    name: "GraduationRequirementReport",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,
        DatatableFilter,
        DepartmentSelectbox,
        ProgramSelectbox,
        FacultySelectbox,
        SemestersSelectbox,
        AcademicYearsSelectbox
    },
    metaInfo() {
        return {
            title: this.$t("student_graduation_requirement_report"),
        };
    },
    data() {
        return {
            creditAktsNumber1:null,
            creditAktsNumber2:null,
            rangeAktsNumber1:null,
            rangeAktsNumber2:null,

            datatable: {
                isLoading: false,
                rows: [],
                filterStatus: true,
                showTable: false,
                total: 0,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20
                },
                columns: [
                    {
                        label: this.$t('student_number'),
                        field: 'student_number',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('national_id'),
                        field: 'national_id',
                        sortable: false,
                        hidden: false,
                    },
                    {
                        label: this.$t('student_name'),
                        field: 'name',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('surname'),
                        field: 'surname',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('faculty_code'),
                        field: 'faculty_code',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('faculty_name'),
                        field: this.getLocaleField('faculty_name'),
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('department_code'),
                        field: 'department_code',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('department_name'),
                        field: this.getLocaleField('department_name'),
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('program_code'),
                        field: 'program_code',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('program_name'),
                        field: this.getLocaleField('program_name'),
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('registration_type'),
                        field: 'registration_type',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('registration_date'),
                        field: 'registration_date',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('registration_semester'),
                        field: 'registration_semester',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('cgpa'),
                        field: 'cgpa',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('class'),
                        field: 'class',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('last_course_registration_year'),
                        field: 'last_course_registration_academic_year',
                        sortable: false,
                        hidden: false
                    },
                    {
                        label: this.$t('last_course_registration_semester'),
                        field: 'last_course_registration_semester',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('number_of_registered_courses'),
                        field: 'course_count',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('student_semester_count'),
                        field: 'student_semester_count',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('yoksis_number_of_semesters'),
                        field: 'yoksis_number_of_semesters',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t('required_course_count'),
                        field: 'required_course_count',
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("required_course_ects_credit"),
                        field: "required_course_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("required_course_credit"),
                        field: "required_course_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("gep_course_count"),
                        field: "gep_course_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("gep_course_credit"),
                        field: "gep_course_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("gep_course_ects_credit"),
                        field: "gep_course_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("dep_course_count"),
                        field: "dep_course_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("dep_course_credit"),
                        field: "dep_course_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("dep_course_ects_credit"),
                        field: "dep_course_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("non_dep_course_count"),
                        field: "non_dep_course_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("non_dep_course_credit"),
                        field: "non_dep_course_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("non_dep_course_ects_credit"),
                        field: "non_dep_course_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_required_course_count"),
                        field: "completed_required_course_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_required_ects_credit"),
                        field: "completed_required_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_required_credit"),
                        field: "completed_required_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_gep_course_count"),
                        field: "completed_gep_course_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_gep_ects_credit"),
                        field: "completed_gep_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_gep_credit"),
                        field: "completed_gep_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_dep_course_count"),
                        field: "completed_dep_course_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_dep_ects_credit"),
                        field: "completed_dep_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_dep_credit"),
                        field: "completed_dep_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_non_dep_course_count"),
                        field: "completed_non_dep_course_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_non_dep_ects_credit"),
                        field: "completed_non_dep_ects_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("completed_non_dep_credit"),
                        field: "completed_non_dep_credit",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("internship_remaining_count"),
                        field: "remaining_j_courses_count",
                        sortable: false,
                        hidden: true
                    },
                    {
                        label: this.$t("student_status"),
                        field: "student_status",
                        sortable: false,
                        hidden: false
                    }
                ],
            },

        }
    },
    created() {
        this.filterClearAndGetRow();
    },
    methods: {
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            let cgpa_between_start = null
            let cgpa_between_end = null
            if(this.datatable.queryParams.filter.cgpa_between_start && this.datatable.queryParams.filter.cgpa_between_end){
                this.datatable.queryParams.filter.cgpa_between = this.datatable.queryParams.filter.cgpa_between_start+','+this.datatable.queryParams.filter.cgpa_between_end
                cgpa_between_start = this.datatable.queryParams.filter.cgpa_between_start
                cgpa_between_end = this.datatable.queryParams.filter.cgpa_between_end
            } else {
                delete this.datatable.queryParams.filter.cgpa_between
            }
            delete this.datatable.queryParams.filter.cgpa_between_start
            delete this.datatable.queryParams.filter.cgpa_between_end

            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            return StudentProgramService.getGraduationRequirement(config)
                .then((response) => {

                    this.datatable.rows = response.data.data
                    this.datatable.total = response.data.pagination.total

                    this.datatable.queryParams.filter.cgpa_between_start = cgpa_between_start
                    this.datatable.queryParams.filter.cgpa_between_end = cgpa_between_end
                }).catch((e) => {
                    this.showErrors(e)
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        filter() {
            this.getRows();
        },

        filterClear() {
            this.datatable.queryParams.filter = {
                name: null,
                surname: null,
                student_number: null,
                registration_academic_year: null,
                registration_semester: null,
                faculty_code: null,
                program_code: null,
                department_code: null,

            }
        },
        filterClearAndGetRow() {
            this.filterClear();
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        async exportExcel() {
            const valid = await this.$refs.form.validate()
            if (!valid) {
                return
            }

            if(this.datatable.queryParams.filter.cgpa_between_start && this.datatable.queryParams.filter.cgpa_between_end){
                this.datatable.queryParams.filter.cgpa_between = this.datatable.queryParams.filter.cgpa_between_start+','+this.datatable.queryParams.filter.cgpa_between_end
            } else {
                delete this.datatable.queryParams.filter.cgpa_between
            }
            delete this.datatable.queryParams.filter.cgpa_between_start
            delete this.datatable.queryParams.filter.cgpa_between_end
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }

            return StudentProgramService.excelGraduationRequirement(config)
                .then((response) => {
                    this.$toast(this.$t('processing'))
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                }).catch(e => {
                    this.showErrors(e)
                })
        }
    }
}

</script>
